const MarketUnitOptions = {
  'Cryptocurrencies': [
    { value: 0.1, label: '0.1' },
    { value: 0.5, label: '0.5' },
    { value: 1, label: '1' },
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ],
  'Forex (Spot)': [
    { value: 1000, label: '1000' },
    { value: 2000, label: '2000' },
    { value: 5000, label: '5000' },
    { value: 10000, label: '10000' },
    { value: 20000, label: '20000' },
    { value: 50000, label: '50000' },
    { value: 100000, label: '100000' },
    { value: 200000, label: '200000' },
    { value: 500000, label: '500000' },
    { value: 1000000, label: '1000000' }
  ],
  'Commodities': [
    { value: 10, label: '10' },
    { value: 100, label: '100' },
    { value: 500, label: '500' },
    { value: 1000, label: '1000' },
    { value: 5000, label: '5000' },
    { value: 10000, label: '10000' },
    { value: 50000, label: '50000' },
    { value: 100000, label: '100000' },
    { value: 500000, label: '500000' },
    { value: 1000000, label: '1000000' }
  ],
  'ETF': [
    { value: 1, label: '1' },
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
    { value: 200, label: '200' },
    { value: 500, label: '500' },
    { value: 1000, label: '1000' },
    { value: 2000, label: '2000' }
  ],
  'Indices Futures': [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
    { value: 200, label: '200' },
    { value: 500, label: '500' },
    { value: 1000, label: '1000' }
  ],
  'Stocks': [
    { value: 1, label: '1' },
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
    { value: 200, label: '200' },
    { value: 500, label: '500' },
    { value: 1000, label: '1000' },
    { value: 2000, label: '2000' }
  ],
  'Bonds': [
    { value: 1000, label: '1000' },
    { value: 5000, label: '5000' },
    { value: 10000, label: '10000' },
    { value: 20000, label: '20000' },
    { value: 50000, label: '50000' },
    { value: 100000, label: '100000' },
    { value: 200000, label: '200000' },
    { value: 500000, label: '500000' },
    { value: 1000000, label: '1000000' },
    { value: 2000000, label: '2000000' }
  ],
  'Indices (Spot)': [
    { value: 1, label: '1' },
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
    { value: 200, label: '200' },
    { value: 500, label: '500' },
    { value: 1000, label: '1000' },
    { value: 2000, label: '2000' }
  ],
  'No Quote': [
    { value: 1, label: '1' },
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
    { value: 200, label: '200' },
    { value: 500, label: '500' },
    { value: 1000, label: '1000' },
    { value: 2000, label: '2000' }
  ],
  'Default Category': [
    { value: 1, label: '1' },
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
    { value: 200, label: '200' },
    { value: 500, label: '500' },
    { value: 1000, label: '1000' },
    { value: 2000, label: '2000' }
  ]
};
  
  export default MarketUnitOptions;
  