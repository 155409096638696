import Login from "./pages/login/Login.jsx";
import React, { useState, useEffect } from 'react';
import { Outlet, RouterProvider, createBrowserRouter,useNavigate, Navigate } from "react-router-dom";
import { ThemeProvider } from "./contexts/Theme-Context.js";
import { AuthProvider } from "./contexts/Auth-Context";
import { SymbolProvider } from "./contexts/Symbol-Context";
import { AccountManagerProvider } from "./contexts/Account-Manager-Context";
import { ChartProvider } from "./contexts/Chart-Context";
import { MetricsProvider } from "./contexts/Metrics-Context.js";
import { RippleProvider } from "./contexts/Ripple-Context.js";
import Main from "./pages/main/Main.jsx";
import Symbol from "./components/main/symbol/Symbol";
import Chart from "./components/main/chart/Chart";
import OrderPanel from "./components/main/order_panel/Order-Panel";
import OrderBook from "./components/main/order_book/Order-Book";
import AccountManager from "./components/main/account_manager/Account-Manager";
import UserSettings from "./components/main/user_settings/UserSettings";
import MetricsPanel from "./components/main/metrics_panel/Metrics-Panel.jsx";
import "./App.scss";
import { OrderProvider } from "./contexts/Order-Context.js";
import 'react-toastify/dist/ReactToastify.css';


const Layout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('userId')) {
      navigate('/home');
    }else{
      navigate('/');
    }
  }, []);
  return (
    <>
      <Outlet />
    </>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true, // Use "index: true" for the root path
        element: <Login />, // Render the Login component for the root path    
      },
      {
        path: "home", // Use "home" for the "/home" path
        element: <Main />, // Render the Main component for the "/home" path
      },
      {
        path: "/",
        element: <Main />,
        children: [
          { path: "Symbol", element: <Symbol /> },
          { path: "Chart", element: <Chart /> },
          { path: "Order-Panel", element: <OrderPanel /> },
          { path: "Order-Book", element: <OrderBook /> },
          { path: "Account-Manager", element: <AccountManager /> },
          { path: "UserSettings", element: <UserSettings /> },
          { path: "Login", element: <Login /> },
          { path: "MetricsPanel", element: <MetricsPanel /> },
        ],
      },
    ],
  },
]);

function App() {

  return (
    <ThemeProvider>
      <RippleProvider>
        <AuthProvider>
          <AccountManagerProvider>
            <ChartProvider>
              <SymbolProvider>
                <MetricsProvider>
                  <OrderProvider>
                  <div className="App">
                    <RouterProvider router={router} />
                  </div>
                  </OrderProvider>
                </MetricsProvider>
              </SymbolProvider>
            </ChartProvider>
          </AccountManagerProvider>
        </AuthProvider>
      </RippleProvider>
    </ThemeProvider>
  );
}

export default App;
