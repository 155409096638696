import React, { useEffect } from 'react';
import Select from 'react-select';
import { useMetricsContext } from '../../../contexts/Metrics-Context';
const UTCTimeConverter = ({ inSettings = true }) => {
  const {
    currentUTCDate,
    setCurrentUTCDate,
    utcOffset,
    setUTCOffset,
    formatTime,
    utcOffsetInitial,
  } = useMetricsContext();
  useEffect(() => {
    const interval = setInterval(() => {
      const adjustedDate = new Date();
      const [hoursStr, minutesStr] = utcOffset.split(':');
      const hoursOffset = parseInt(hoursStr, 10) || 0;
      const minutesOffset = parseInt(minutesStr, 10) || 0;

      adjustedDate.setUTCHours(
        adjustedDate.getUTCHours() + hoursOffset,
        adjustedDate.getUTCMinutes() + minutesOffset,
      );

      setCurrentUTCDate(adjustedDate);
    }, 1000);

    return () => clearInterval(interval);
  }, [utcOffset]);

  const handleChangeUTCOffset = (selectedOption) => {
    localStorage.setItem('utc_time', selectedOption.value);
    if (selectedOption) {
      setUTCOffset(selectedOption.value);
    }
  };

  const options = [
    { value: '-12:00', label: 'UTC-12:00' },
    { value: '-11:30', label: 'UTC-11:30' },
    { value: '-11:00', label: 'UTC-11:00' },
    { value: '-10:30', label: 'UTC-10:30' },
    { value: '-10:00', label: 'UTC-10:00' },
    { value: '-09:30', label: 'UTC-9:30' },
    { value: '-09:00', label: 'UTC-9:00' },
    { value: '-08:30', label: 'UTC-8:30' },
    { value: '-08:00', label: 'UTC-8:00' },
    { value: '-07:30', label: 'UTC-7:30' },
    { value: '-07:00', label: 'UTC-7:00' },
    { value: '-06:30', label: 'UTC-6:30' },
    { value: '-06:00', label: 'UTC-6:00' },
    { value: '-05:45', label: 'UTC-5:45' },
    { value: '-05:30', label: 'UTC-5:30' },
    { value: '-05:00', label: 'UTC-5:00' },
    { value: '-04:30', label: 'UTC-4:30' },
    { value: '-04:00', label: 'UTC-4:00' },
    { value: '-03:30', label: 'UTC-3:30' },
    { value: '-03:00', label: 'UTC-3:00' },
    { value: '-02:30', label: 'UTC-2:30' },
    { value: '-02:00', label: 'UTC-2:00' },
    { value: '-01:30', label: 'UTC-1:30' },
    { value: '-01:00', label: 'UTC-1:00' },
    { value: '+00:00', label: 'UTC+0' },
    { value: '+01:00', label: 'UTC+1:00' },
    { value: '+02:00', label: 'UTC+2:00' },
    { value: '+02:30', label: 'UTC+2:30' },
    { value: '+03:00', label: 'UTC+3:00' },
    { value: '+03:30', label: 'UTC+3:30' },
    { value: '+04:00', label: 'UTC+4:00' },
    { value: '+04:30', label: 'UTC+4:30' },
    { value: '+05:00', label: 'UTC+5:00' },
    { value: '+05:30', label: 'UTC+5:30' },
    { value: '+05:45', label: 'UTC+5:45' },
    { value: '+06:00', label: 'UTC+6:00' },
    { value: '+06:30', label: 'UTC+6:30' },
    { value: '+07:00', label: 'UTC+7:00' },
    { value: '+07:30', label: 'UTC+7:30' },
    { value: '+08:00', label: 'UTC+8:00' },
    { value: '+08:30', label: 'UTC+8:45' },
    { value: '+09:00', label: 'UTC+9:00' },
    { value: '+09:30', label: 'UTC+9:30' },
    { value: '+10:00', label: 'UTC+10:00' },
    { value: '+10:30', label: 'UTC+10:30' },
    { value: '+11:00', label: 'UTC+11:00' },
    { value: '+11:30', label: 'UTC+11:30' },
    { value: '+12:00', label: 'UTC+12:00' },
    { value: '+13:00', label: 'UTC+13:00' },
    { value: '+14:00', label: 'UTC+14:00' },
  ];
  const orderCustomStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '15px !important',
      width: '100px',
      borderColor: '#484848 !important',
      backgroundColor: '#232323 !important',
      boxShadow: state.isFocused
        ? '0 0 50px #484848 !important'
        : provided.boxShadow,
      // margin: '0.3rem 0.3rem 0 0.8rem !important',
      cursor: 'pointer',
      '&:hover': {
        borderColor: 'rgb(33, 196, 109) !important',
        cursor: 'pointer',
        boxShadow: '0 0 0 transparent !important',
      },
    }),

    singleValue: (provided, state) => ({
      ...provided,
      paddingLeft: '10px !important',
      borderColor: state.isFocused
        ? 'rgb(33, 196, 109) !important'
        : '#484848 !important',
      color: '#c5c5c5 !important',
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      backgroundColor: '#484848 !important',
      borderColor: state.isFocused
        ? 'rgb(33, 196, 109) !important'
        : '#484848 !important',
      color: '#484848 !important',
    }),

    option: (provided, state) => ({
      ...provided,
      // padding: '7px !important',
      padding: inSettings ? '0.3rem !important' : '0 !important',
      cursor: 'pointer',
      color: state.isSelected
        ? 'rgb(33, 196, 109) !important'
        : '#c5c5c5 !important',

      borderBottom: '1px solid #232323',
      backgroundColor: state.isSelected ? '#232323 !important' : 'transparent',

      '&:hover': {
        backgroundColor: 'rgb(33, 196, 109) !important',
        color: '#2d2d2d !important',
      },
      '&:nth-last-child(1)': {
        borderBottom: 'none !important',
      },
    }),

    menu: (provided) => ({
      ...provided,
      backgroundColor: '#3b3a3a !important',
      width: 'calc(100% - 1.6rem) !important',
      margin: '0.3rem 0.8rem 0 0.8rem !important',
      zIndex: 111,
    }),

    menuList: (provided) => ({
      ...provided,
      maxHeight: '130px',
      overflowY: 'auto',
    }),
  };
  return (
    <div className='utc-time' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      {inSettings && <h2>Current Time:</h2>}
      <span
        style={{
          color: utcOffsetInitial !== utcOffset ? `rgb(245, 36, 36)` : ``,
        }}
        title={
          utcOffsetInitial !== utcOffset
            ? `User time is different than computer's time`
            : ''
        }
      >
        {formatTime(currentUTCDate)}
      </span>
      <Select
        styles={orderCustomStyles}
        value={{
          value: utcOffset,
          label: `UTC ${utcOffset >= 0 ? '+' : ''}${utcOffset}`,
        }}
        onChange={handleChangeUTCOffset}
        options={options}
        menuPlacement='auto'
      />
    </div>
  );
};

export default UTCTimeConverter;
